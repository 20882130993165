import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import {
  addDevice,
  addDevices,
  deleteDevice,
  Device,
  Devices,
} from "../features/devices/deviceSlice";
import { LanguageText } from "../dictionary/GroupNotificationText";

export type SaveDevice = {
  id: string;
  updates?: DeviceUpdates[];
};

type DeviceUpdates = {
  phenom?: string;
  element?: number;
  field?: string;
  value?: string | number;
};

/*
API requests for device related data
*/

export const deviceAPI = createApi({
  reducerPath: "deviceAPI",
  //base query for all requests: token used in all requests added to header
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Device", "Devices"],
  endpoints: (builder) => ({
    //get device list - Takes token and returns array of devices
    getDevices: builder.query({
      query: () => {
        return {
          url: "/devices",
        };
      },
      transformResponse: (result: Devices) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addDevices(data));
        } catch (error) {}
      },
      providesTags: ["Devices"],
    }),
    //get device info - Takes token and returns device info
    getDevice: builder.query({
      query: (eui: string) => {
        return {
          url: `/device/${eui}`,
        };
      },
      transformResponse: (result: Device) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addDevice(data));
        } catch (error) {}
      },
      providesTags: ["Device"],
    }),
    //edit device info - Takes token and device data and returns new device
    editDevice: builder.mutation({
      query: (device: SaveDevice) => {
        return {
          url: `/device/${device.id}/edit`,
          method: "PATCH",
          body: device,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {}
      },
      invalidatesTags: ["Device"],
    }),
    updateDeviceSettings: builder.mutation({
      query: (args: {
        deviceID: string;
        deviceSettings: {
          magsw_on?: boolean;
          offline_notification_active?: boolean;
          offline_notification_recipient_id?: string;
        };
        language: LanguageText | null;
      }) => {
        const { deviceID, deviceSettings, language } = args;
        return {
          url: `device/${deviceID}/updateSettings`,
          method: "PUT",
          body: deviceSettings,
        };
      },
      invalidatesTags: ["Device"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { language } = args;
        try {
          const { data } = await queryFulfilled;
          toast.success(
            language?.messages?.toast?.updateNotification?.success || data.message
          );
        } catch (error: any) {
          const errorMessage =
            error?.error?.data?.error?.message || error.message || "An error occurred";
          toast.error(
            language?.messages?.toast?.updateNotification?.error || errorMessage
          );
        }
      },
    }),

    //claim new device
    claimDevice: builder.mutation({
      query: (ClaimDevice: {
        device_id: string;
        group_id: string;
        claim_token: string;
      }) => {
        return {
          url: "/device/claim",
          method: "POST",
          body: ClaimDevice,
        };
      },
      transformResponse: (result: { message: string; deviceID: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {}
      },
      invalidatesTags: ["Devices"],
    }),
    //unclaim new device
    unclaimDevice: builder.mutation({
      query: (UnclaimDevice: { device_id: string; group_id: string }) => {
        return {
          url: "/device/unclaim",
          method: "POST",
          body: UnclaimDevice,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(deleteDevice(args.device_id));
        } catch (error) {}
      },
      invalidatesTags: ["Devices"],
    }),
    //reset node downlink message
    downlinkResetNode: builder.mutation({
      query: (device_id: string) => {
        return {
          url: `/device/${device_id}/resetnode`,
          method: "POST",
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          toast.success(data.message);
        } catch (error) {}
      },
    }),
    //reset all sensors downlink message
    downlinkResetAllSensors: builder.mutation({
      query: (device_id: string) => {
        return {
          url: `/device/${device_id}/resetallsensors`,
          method: "POST",
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          toast.success(data.message);
        } catch (error) {}
      },
    }),
    //toggle sensing downlink message
    downlinkToggleSensing: builder.mutation({
      query: (device_id: string) => {
        return {
          url: `/device/${device_id}/togglesensing`,
          method: "POST",
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          toast.success(data.message);
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useEditDeviceMutation,
  useClaimDeviceMutation,
  useUnclaimDeviceMutation,
  useDownlinkResetNodeMutation,
  useDownlinkResetAllSensorsMutation,
  useDownlinkToggleSensingMutation,
  useUpdateDeviceSettingsMutation,
} = deviceAPI;
