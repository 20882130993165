// import React from "react";

// interface ToggleSwitchProps {
//   isActive: boolean;
//   onToggle: () => void;
// }

// const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onToggle }) => {
//   return (
//     <div
//       className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
//         isActive ? "bg-blue-500" : "bg-gray-400"
//       }`}
//       onClick={onToggle}
//     >
//       <div
//         className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out flex items-center justify-center ${
//           isActive ? "translate-x-6" : "translate-x-0"
//         }`}
//       >
//         {isActive ? (
//           <svg
//             className="w-4 h-4 text-green-500"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M5 13l4 4L19 7"
//             />
//           </svg>
//         ) : (
//           <svg
//             className="w-4 h-4 text-gray-500"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M6 18L18 6M6 6l12 12"
//             />
//           </svg>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ToggleSwitch;

import React from "react";

interface ToggleSwitchProps {
  isActive: boolean;
  onToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onToggle }) => {
  return (
    <label className={`inline-flex items-center cursor-pointer`}>
      <input type="checkbox" className="hidden" checked={isActive} onChange={onToggle} />
      <div
        className={`w-10 h-5 rounded-full transition-colors duration-300 ease-in-out relative ${
          isActive ? "bg-blue-500" : "bg-gray-400"
        }`}
      >
        <div
          className={`w-5 h-5 bg-white rounded-full shadow-md transform duration-300 ease-in-out absolute top-0 left-0 ${
            isActive ? "translate-x-5" : "translate-x-0"
          }`}
        >
          {isActive ? (
            <svg
              className="w-3 h-3 text-green-500 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          ) : (
            <svg
              className="w-3 h-3 text-gray-500 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
      </div>
    </label>
  );
};

export default ToggleSwitch;
