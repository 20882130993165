import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import _ from "lodash";
import { useState, useEffect, SetStateAction } from "react";
import { TbCircleDotted } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DeviceText";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import {
  UplinkData,
  useDeleteDataWithinRangeMutation,
  useGetLatestDataQuery,
  useLazyGetDataQuery,
  useLazyGetDataWithinRangeQuery,
} from "../../services/dataAPI";
import {
  deviceAPI,
  SaveDevice,
  useEditDeviceMutation,
  useGetDeviceQuery,
} from "../../services/deviceAPI";
import { handleError } from "../../utils/ErrorHandling";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Button } from "../../components/Button";
import DownloadModal from "./DownloadModal";
import {
  BsBatteryFull,
  BsDownload,
  BsFillCloudRainFill,
  BsGraphUp,
  BsTrash,
} from "react-icons/bs";
import { MdSignalWifiStatusbar3Bar, MdThermostat } from "react-icons/md";
import { FiWind } from "react-icons/fi";
import { IoIosWater } from "react-icons/io";
import { Device } from "../../features/devices/deviceSlice";
import Graph from "../../components/dashboard/charts/Graph";
import { AiOutlineLineChart, AiOutlineBarChart, AiOutlineDotChart } from "react-icons/ai";
import { DataState, selectData } from "../../features/data/dataSlice";
import { DashboardPhenomData } from "../../services/dashboardAPI";
import AcceptModal from "../../components/modals/AcceptModal";
import { convertSecondsToDays } from "../../utils/DateFormating";

function EditDeviceParameters() {
  const { deviceid } = useParams();
  const {
    data: device,
    isSuccess: isGetDeviceSuccess,
    isError: isGetDeviceError,
    error: getDeviceError,
    isFetching: isGetDeviceFetching,
  } = useGetDeviceQuery(deviceid ?? skipToken);

  const [
    getDataWithinRange,
    {
      data: dataWithinRange,
      isSuccess: isGetDataWithinRangeSuccess,
      isError: isGetDataWithinRangeError,
      error: getDataWithinRangeError,
      isFetching: isGetDataWithinRangeFetching,
    },
  ] = useLazyGetDataWithinRangeQuery({});

  const [
    deleteData,
    {
      data: deleteDataResponse,
      isSuccess: isDeleteDataSuccess,
      isError: isDeleteDataError,
      error: deleteDataError,
    },
  ] = useDeleteDataWithinRangeMutation();

  const configurations = device?.configuration;
  const gateways = device?.gateways;

  var nodeData = useAppSelector(selectData);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [seed, setSeed] = useState(0);
  const [editPermission, setEditPermission] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showParamViewModal, setShowParamViewModal] = useState(false);
  const [showDeleteDataModal, setShowDeleteDataModal] = useState(false);
  const [showDeleteDataConfirmModal, setShowDeleteDataConfirmModal] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [dataDeleteMode, setDataDeleteMode] = useState("all");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [paramViewModalState, setParamViewModalState] = useState<ParamViewModalProps>({
    setInternalModal: setShowParamViewModal,
    device: device,
    sensor: undefined,
    fetching: isGetDataWithinRangeFetching,
    nodeData: nodeData,
  });

  const [lineList, setLineList] = useState<
    { name: string; pheno: string | number; checked: boolean; config: string }[]
  >([]);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  useEffect(() => {
    if (device) {
      if (device.eui) {
        setParamViewModalState({
          ...paramViewModalState,
          device: device,
        });
      }
    }
  }, [device]);

  useEffect(() => {
    if (nodeData) {
      setParamViewModalState({
        ...paramViewModalState,
        nodeData: nodeData,
      });
    }
  }, [nodeData]);

  const [
    saveDevice,
    {
      data: saveDeviceResponse,
      isSuccess: isSaveDeviceSuccess,
      isLoading: isSaveDeviceLoading,
      isError: isSaveDeviceError,
      error: saveDeviceError,
    },
  ] = useEditDeviceMutation();

  const [
    getDeviceData,
    {
      isSuccess: isGetDeviceDataSuccess,
      isError: isGetDeviceDataError,
      isFetching: isGetDeviceDataFetching,
      error: getDeviceDataError,
    },
  ] = useLazyGetDataQuery();

  const { data: latestData } = useGetLatestDataQuery(
    { eui: device?.eui ?? "" },
    { pollingInterval: 60000 }
  );

  useEffect(() => {
    if (isGetDeviceSuccess) {
      if (user.groups[device.group_id]) {
        setEditPermission(
          user.groups[device.group_id].permissions.can_edit_devices?.can_edit_all ??
            user.groups[device.group_id].permissions.can_edit_devices?.can_edit_devices?.[
              device.id
            ] ??
            false
        );
      } else {
        setEditPermission(false);
      }
    }
  }, [device, user, isGetDeviceSuccess]);

  useEffect(() => {
    if (isSaveDeviceError) {
      handleError(saveDeviceError);
    }
  }, [saveDeviceError, isSaveDeviceError]);

  useEffect(() => {
    if (isSaveDeviceSuccess) {
      toast.success(language.editDevice.viewEditDevice.toasts.deviceSaved);
    }
  }, [
    saveDeviceResponse,
    isSaveDeviceSuccess,
    language.editDevice.viewEditDevice.toasts.deviceSaved,
  ]);

  async function SaveDevice(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (device) {
      const newUpdatedDevice: SaveDevice = {
        id: device.id,
        updates: [],
      };
      _.map(Object.values(document.forms[0]), (value, index) => {
        if (document.forms[1][index]) {
          if ((document.forms[1][index] as HTMLInputElement).hasOwnProperty("value")) {
            newUpdatedDevice.updates?.push({
              phenom: JSON.parse((document.forms[1][index] as HTMLInputElement).id)
                .config,
              element: JSON.parse((document.forms[1][index] as HTMLInputElement).id)
                .ident,
              field: "description",
              value: (document.forms[1][index] as HTMLInputElement).value,
            });
          }
        }
      });
      saveDevice(newUpdatedDevice);
    }
  }

  const phenomenaList: JSX.Element[] = [];
  const deviceParamsList: JSX.Element[] = [];
  var phenoList: { [key: string]: { [key: string]: any } } = {};

  useEffect(() => {
    setLineList([]);
    if (configurations !== undefined) {
      for (let config in configurations) {
        _.map(
          configurations[config],
          (sensor: { description: string; elemID: string | number }) => {
            if (gateways === undefined || gateways[sensor.elemID] === undefined) {
              setLineList((lineList) =>
                [...lineList].concat([
                  {
                    name: sensor.description,
                    pheno: sensor.elemID,
                    checked: true,
                    config: config,
                  },
                ])
              );
            }
          }
        );
      }
    }
  }, [configurations, gateways]);

  if (configurations !== undefined) {
    for (let config in configurations) {
      _.map(
        configurations[config],
        (sensor: { elemID: string | number }, index: number) => {
          if (gateways === undefined || gateways[sensor.elemID] === undefined) {
            if (phenoList[sensor.elemID] === undefined) {
              phenoList[sensor.elemID] = {};
            }
            phenoList[sensor.elemID][config] = {
              ...sensor,
              ident: index,
              config: config,
            };
          }
        }
      );
    }
    phenoList = Object.fromEntries(
      Object.entries(phenoList).sort(([, a], [, b]) => b.ts - a.ts)
    );
  }

  const timeNow = Date.now();
  const time7DaysAgo = timeNow - 604800000;

  _.map(Object.keys(phenoList), (pheno, index) => {
    pheno === "frames" || pheno === "sf" || pheno === "vlt2"
      ? deviceParamsList.push(
          <>
            {_.map(Object.values(phenoList[pheno]), (sensor) => {
              function openParamViewModal() {
                if (nodeData.data[device?.eui ?? ""] === undefined) {
                  getDataWithinRange({
                    eui: [device?.eui ?? ""],
                    start: time7DaysAgo,
                    end: timeNow,
                  });
                }
                setParamViewModalState({
                  ...paramViewModalState,
                  sensor: sensor,
                  nodeData: nodeData,
                  fetching: isGetDeviceFetching,
                });
                setShowParamViewModal(true);
              }
              if (gateways === undefined || gateways[sensor.elemID] === undefined) {
                return (
                  <PhenomDisplay
                    sensor={sensor}
                    latestData={latestData}
                    isGetDeviceFetching={isGetDeviceFetching}
                    editPermission={editPermission}
                    seed={seed}
                    key={index}
                    openParamViewModal={openParamViewModal}
                  />
                );
              }
            })}
          </>
        )
      : phenomenaList.push(
          <>
            {_.map(Object.values(phenoList[pheno]), (sensor) => {
              function openParamViewModal() {
                if (nodeData.data[device?.eui ?? ""] === undefined) {
                  getDataWithinRange({
                    eui: [device?.eui ?? ""],
                    start: time7DaysAgo,
                    end: timeNow,
                  });
                }
                setParamViewModalState({
                  ...paramViewModalState,
                  sensor: sensor,
                  nodeData: nodeData,
                });
                setShowParamViewModal(true);
              }
              if (gateways === undefined || gateways[sensor.elemID] === undefined) {
                return (
                  <PhenomDisplay
                    sensor={sensor}
                    latestData={latestData}
                    isGetDeviceFetching={isGetDeviceFetching}
                    editPermission={editPermission}
                    seed={seed}
                    key={index}
                    openParamViewModal={openParamViewModal}
                  />
                );
              }
            })}
          </>
        );
  });

  const DeleteDataModal: JSX.Element = (
    <AcceptModal
      onAccept={() => {
        switch (dataDeleteMode) {
          case "all":
            setShowDeleteDataModal(false);
            setShowDeleteDataConfirmModal(true);
            break;
          case "before":
          case "since":
            if (tempStartDate !== undefined && tempStartDate !== "") {
              setShowDeleteDataModal(false);
              setShowDeleteDataConfirmModal(true);
            } else {
              setStartDateError(true);
            }
            break;
          case "between":
            if (tempStartDate !== undefined && tempStartDate !== "") {
              if (tempEndDate !== undefined && tempEndDate !== "") {
                setShowDeleteDataModal(false);
                setShowDeleteDataConfirmModal(true);
              } else {
                setEndDateError(true);
              }
            } else {
              setStartDateError(true);
            }
            break;
          default:
            break;
        }
      }}
      onCancel={() => {
        setShowDeleteDataModal(false);
      }}
      Title={
        language.editDevice.editDeviceParameters.modals.title.phrase1 +
        (device?.name ?? language.editDevice.editDeviceParameters.modals.thisDevice) +
        language.editDevice.editDeviceParameters.modals.title.phrase2
      }
      Body={
        <>
          <input
            type="radio"
            id="all"
            name={"ALL"}
            value={"all"}
            checked={dataDeleteMode === "all"}
            onChange={() => {
              setDataDeleteMode("all");
            }}
          />
          <label className="text-black font-semibold px-2">
            {language.editDevice.editDeviceParameters.modals.deleteData.labels.all}
          </label>
          <br />
          <input
            type="radio"
            id="before"
            name={"BEFORE"}
            value="before"
            checked={dataDeleteMode === "before"}
            onChange={() => {
              setDataDeleteMode("before");
            }}
          />
          <label className="text-black font-semibold px-2">
            {language.editDevice.editDeviceParameters.modals.deleteData.labels.before}
          </label>
          <input
            type="radio"
            id="since"
            name={"SINCE"}
            value="since"
            checked={dataDeleteMode === "since"}
            onChange={() => {
              setDataDeleteMode("since");
            }}
          />
          <label className="text-black font-semibold px-2">
            {language.editDevice.editDeviceParameters.modals.deleteData.labels.since}
          </label>
          <input
            type="radio"
            id="between"
            name={"between"}
            value="between"
            checked={dataDeleteMode === "between"}
            onChange={() => {
              setDataDeleteMode("between");
            }}
          />
          <label className="text-black font-semibold px-2">{"Between"}</label>
          <br />
          <div className="inline-flex pt-1 h-max">
            <input
              type="date"
              name="start"
              id="start"
              disabled={dataDeleteMode === "all"}
              className={`shadow-sm focus:ring-green-500 focus:border-green-500 mr-0 block sm:text-sm border-gray-300 rounded-md ${
                dataDeleteMode === "all" ? "bg-gray-200" : "bg-white"
              } ${startDateError ? "border-red-500 ring-red-500" : ""}`}
              min={new Date(0).toISOString().split(".")[0]}
              max={new Date().toISOString().slice(0, 10)}
              step={"any"}
              value={tempStartDate}
              onChange={(e) => {
                setStartDateError(false);
                setTempStartDate(new Date(e.target.value).toISOString().slice(0, 10));
              }}
            />
            {dataDeleteMode === "between" && (
              <>
                <div className="h-full px-1 py-2 flex items-center">
                  <label className="text-black font-semibold">{"-"}</label>
                </div>
                <input
                  type="date"
                  name="end"
                  id="end"
                  disabled={dataDeleteMode !== "between"}
                  className={`shadow-sm focus:ring-green-500 focus:border-green-500 mr-0 block sm:text-sm border-gray-300 rounded-md ${
                    endDateError ? "border-red-500 ring-red-500" : ""
                  }`}
                  min={new Date(0).toISOString().split(".")[0]}
                  max={new Date().toISOString().slice(0, 10)}
                  step={"any"}
                  value={tempEndDate}
                  onChange={(e) => {
                    setEndDateError(false);
                    setTempEndDate(new Date(e.target.value).toISOString().slice(0, 10));
                  }}
                />
              </>
            )}
          </div>
        </>
      }
      AcceptButton={language.editDevice.editDeviceParameters.modals.buttons.accept}
      AcceptButtonColour={"red"}
      CancelButton={language.editDevice.editDeviceParameters.modals.buttons.cancel}
      loading={false}
    />
  );

  const Modals: JSX.Element = (
    <>
      {showDownloadModal && (
        <DownloadModal
          setInternalModal={setShowDownloadModal}
          lineList={lineList}
          setLineList={setLineList}
          device={
            device ? { name: device.name, eui: device.eui } : { name: "0", eui: "0" }
          }
          getDeviceData={getDeviceData}
        />
      )}
      {showParamViewModal && (
        <ParamViewModal
          {...{ ...paramViewModalState, fetching: isGetDataWithinRangeFetching }}
        />
      )}
      {showDeleteDataModal && DeleteDataModal}
      {showDeleteDataConfirmModal && (
        <AcceptModal
          onAccept={() => {
            switch (dataDeleteMode) {
              case "all":
                deleteData({
                  eui: device?.eui ?? "",
                  start: new Date(0).toISOString().slice(0, 10),
                  end: new Date().toISOString().slice(0, 10),
                });
                break;
              case "before":
                deleteData({
                  eui: device?.eui ?? "",
                  start: new Date(0).toISOString().slice(0, 10),
                  end: tempStartDate,
                });
                break;
              case "since":
                deleteData({
                  eui: device?.eui ?? "",
                  start: tempStartDate,
                  end: new Date().toISOString().slice(0, 10),
                });
                break;
              case "between":
                deleteData({
                  eui: device?.eui ?? "",
                  start: tempStartDate,
                  end: tempEndDate,
                });
                break;
              default:
                break;
            }
            setShowDeleteDataConfirmModal(false);
          }}
          onCancel={() => {
            setShowDeleteDataConfirmModal(false);
          }}
          Title={
            language.editDevice.editDeviceParameters.modals.title.phrase1 +
            (device?.name ?? language.editDevice.editDeviceParameters.modals.thisDevice) +
            language.editDevice.editDeviceParameters.modals.title.phrase2
          }
          Body={
            <>
              <b>
                {
                  language.editDevice.editDeviceParameters.modals.deleteDataConfirm.body
                    .phrase1
                }
              </b>
              {" " +
                language.editDevice.editDeviceParameters.modals.deleteDataConfirm.body
                  .phrase2 +
                " " +
                (device?.name ??
                  language.editDevice.editDeviceParameters.modals.thisDevice)}{" "}
              {
                language.editDevice.editDeviceParameters.modals.deleteDataConfirm.body
                  .phrase3
              }{" "}
              <b>
                {dataDeleteMode !== "all"
                  ? " " +
                    language.editDevice.editDeviceParameters.modals.deleteData.labels[
                      dataDeleteMode
                    ].toLowerCase() +
                    " " +
                    language.editDevice.editDeviceParameters.modals.deleteDataConfirm.body
                      .phrase4 +
                    " " +
                    tempStartDate +
                    (dataDeleteMode === "between" ? " and " + tempEndDate : "")
                  : ""}
              </b>{" "}
              {
                language.editDevice.editDeviceParameters.modals.deleteDataConfirm.body
                  .phrase5
              }
            </>
          }
          AcceptButton={language.editDevice.editDeviceParameters.modals.buttons.accept}
          AcceptButtonColour={"red"}
          CancelButton={language.editDevice.editDeviceParameters.modals.buttons.cancel}
          loading={false}
        />
      )}
    </>
  );

  const ParamsAndSensors: JSX.Element = (
    <>
      <div className="flex-wrap flex">
        <div className="h-full w-full">
          {configurations !== undefined && (
            <div className="space-y-3">
              <div>
                <h1 className="font-bold text-xl w-full">
                  {language.editDevice.editDeviceParameters.deviceParameters}
                </h1>
                <div className="bg-gray-200 rounded-md p-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                  {deviceParamsList}
                </div>
              </div>
              <div>
                <h2 className="font-bold text-xl">
                  {language.editDevice.editDeviceParameters.sensors}
                </h2>
                <div className="bg-gray-200 rounded-md p-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                  {phenomenaList}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const FormattedTimeStamp: JSX.Element = (
    <>
      {latestData !== undefined && (
        <div>
          <h2 className="font-semibold text-lg">
            Last Fetch:{" "}
            {latestData.ts !== undefined
              ? new Date(latestData.ts).toLocaleString("en-GB") + " ("
              : "Never "}
            {latestData.ts !== undefined &&
            Math.floor(
              (new Date().getTime() - new Date(latestData.ts).getTime()) / 86400000
            ) === 0
              ? Math.floor(
                  (new Date().getTime() - new Date(latestData.ts).getTime()) / 3600000
                ) === 0
                ? Math.floor(
                    (new Date().getTime() - new Date(latestData.ts).getTime()) / 60000
                  ) === 0
                  ? "Just now)"
                  : Math.floor(
                      (new Date().getTime() - new Date(latestData.ts).getTime()) / 60000
                    ) +
                    " minute" +
                    (Math.floor(
                      (new Date().getTime() - new Date(latestData.ts).getTime()) / 60000
                    ) === 1
                      ? ""
                      : "s") +
                    " ago)"
                : Math.floor(
                    (new Date().getTime() - new Date(latestData.ts).getTime()) / 3600000
                  ) +
                  " hour" +
                  (Math.floor(
                    (new Date().getTime() - new Date(latestData.ts).getTime()) / 3600000
                  ) === 1
                    ? ""
                    : "s") +
                  " ago)"
              : Math.floor(
                  (new Date().getTime() - new Date(latestData.ts).getTime()) / 86400000
                ) +
                " day" +
                (Math.floor(
                  (new Date().getTime() - new Date(latestData.ts).getTime()) / 86400000
                ) === 1
                  ? ""
                  : "s") +
                " ago)"}
          </h2>
        </div>
      )}
    </>
  );

  const Buttons: JSX.Element = (
    <>
      <div className="inline-flex w-full pb-4 lg:pb-0 justify-between">
        {configurations !== undefined && (
          <div className="inline-flex space-x-2 w-min mt-2">
            <div className="w-min h-min">
              <Button
                colour="blue"
                onClick={() => {
                  setShowDownloadModal(true);
                }}
                disabled={isGetDeviceDataFetching}
                label={
                  isGetDeviceDataFetching ? (
                    <Spinner colour="fill-blue-600" />
                  ) : (
                    <BsDownload size={24} color="white" />
                  )
                }
              />
            </div>
            <div className="w-min h-min">
              <Button
                colour="red"
                label={<BsTrash size={24} color="white" />}
                onClick={() => {
                  setShowDeleteDataModal(true);
                }}
              />
            </div>
          </div>
        )}
        {editPermission && (
          <div className="inline-flex w-full flex-row-reverse space-x-2">
            <div className="ml-2 w-auto mt-2">
              <Button
                label={
                  isSaveDeviceLoading ? (
                    <Spinner colour="fill-blue-600" />
                  ) : (
                    language.editDevice.buttons.save
                  )
                }
                type="submit"
                colour="green"
              />
            </div>
            <div className="ml-2 w-auto mt-2">
              <Button
                label={language.editDevice.buttons.cancel}
                onClick={() => {
                  dispatch(deviceAPI.util.invalidateTags(["Device"]));
                  setSeed(Math.random());
                }}
                colour="white"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {Modals}
      <div className="p-4 pt-1 bg-white rounded-md">
        <form className="h-full flex flex-col justify-between" onSubmit={SaveDevice}>
          {ParamsAndSensors}
          {FormattedTimeStamp}
          {Buttons}
        </form>
      </div>
    </>
  );
}

export default EditDeviceParameters;

type PhenomDisplayProps = {
  sensor: {
    description: string;
    elemID: string;
    ident: string;
    config: string;
    sensor_sn: string;
    unit: string;
  };
  latestData: UplinkData | undefined;
  isGetDeviceFetching: boolean;
  editPermission: boolean;
  seed: number;
  openParamViewModal: () => void;
};

function PhenomDisplay({
  sensor,
  latestData,
  isGetDeviceFetching,
  editPermission,
  seed,
  openParamViewModal,
}: PhenomDisplayProps) {
  // sensor.elemID === "sw_sechl0" && console.log("Latest data:", latestData);
  function getIcon(config: string) {
    switch (config) {
      case "f_cnt":
        return <BsGraphUp size={128} />;
      case "spreading_factor":
        return <MdSignalWifiStatusbar3Bar size={128} />;
      case "vlt2":
        return <BsBatteryFull size={128} />;
      case "ta":
        return <MdThermostat size={128} />;
      case "wda":
        return <FiWind size={128} />;
      case "wsa":
        return <FiWind size={128} />;
      case "psr":
        return <BsFillCloudRainFill size={128} />;
      case "pt":
        return <BsFillCloudRainFill size={128} />;
      case "rh":
        return <IoIosWater size={128} />;
      default:
        return <TbCircleDotted size={128} />;
    }
  }

  return (
    <div className="w-full h-fit flex flex-col items-center justify-center">
      <div className="relative" onClick={() => openParamViewModal()}>
        <div className="absolute w-full h-full flex items-center justify-center opacity-10">
          {getIcon(sensor.config)}
        </div>
        <div className="w-fit p-1 mb-1 rounded-xl bg-gray-100 min-w-[220px] min-h-[180px] flex flex-col justify-between">
          <div className="flex h-fit w-full justify-center items-center">
            <h1>
              {sensor.sensor_sn === ""
                ? sensor.elemID
                : sensor.elemID + " - " + sensor.sensor_sn.toLocaleUpperCase()}
            </h1>
          </div>
          <div className="flex h-full w-full justify-center items-center">
            <NumberDisplay
              latestData={latestData}
              ChartData={{ elemID: sensor.elemID, phenom: sensor.config }}
              unit={sensor.unit}
            />
          </div>
          <div className="z-[1]" onClick={(e) => e.stopPropagation()}>
            <FormInput
              key={seed}
              loading={isGetDeviceFetching}
              title={false}
              htmlFor={JSON.stringify({ config: sensor.config, ident: sensor.ident })}
              label={
                sensor.sensor_sn === ""
                  ? sensor.elemID
                  : sensor.elemID + " - " + sensor.sensor_sn.toLocaleUpperCase()
              }
              value={sensor?.description}
              disabled={!editPermission}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

type NumberDisplayProps = {
  latestData: UplinkData | undefined;
  ChartData: { elemID: string; phenom: string };
  unit: string;
};

function NumberDisplay({ latestData, ChartData, unit }: NumberDisplayProps) {
  const [dataValue, setDataValue] = useState<number>();

  function dataUnitFilter(dataUnit: string | undefined) {
    switch (dataUnit) {
      case undefined:
        return "";
      case "℃":
        return "°C";
      case "V":
        return "";
      case "mm/tip":
        return "mm";
      default:
        return dataUnit;
    }
  }

  useEffect(() => {
    if (latestData?.data_points?.[ChartData.phenom]?.[ChartData.elemID] !== undefined) {
      setDataValue(
        Number(latestData.data_points[ChartData.phenom][ChartData.elemID].toFixed(1))
      );
    }
  }, [ChartData, latestData]);

  return ChartData?.elemID === "sw_sechl0" || ChartData?.elemID === "sw_seclh0" ? (
    <>
      {dataValue !== undefined && (
        <div className="flex flex-row-reverse items-center rounded-md bg-gray-100">
          <div className="h-full flex flex-row text-center items-center justify-center">
            {(() => {
              const { days, hours, minutes, seconds } = convertSecondsToDays(dataValue);
              return (
                <div className="text-3xl h-max">
                  {days > 0 && `${days}d `}
                  {hours > 0 && `${hours}:`}
                  {minutes > 0 && `${minutes}:`}
                  {`${seconds}s`}
                </div>
              );
            })()}
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      {dataValue !== undefined && (
        <div className="flex flex-row-reverse items-center rounded-md bg-gray-100">
          <div className="h-full flex flex-row text-center items-center justify-center">
            <div className="text-6xl h-max">{dataValue}</div>
            <div className="h-full text-2xl text-end">{dataUnitFilter(unit)}</div>
          </div>
        </div>
      )}
    </>
  );
}

type ParamViewModalProps = {
  setInternalModal: React.Dispatch<SetStateAction<boolean>>;
  device: Device | undefined;
  sensor:
    | {
        description: string;
        elemID: string;
        ident: string;
        config: string;
        sensor_sn: string;
        unit: string;
      }
    | undefined;
  fetching: boolean;
  nodeData: DataState | undefined;
};

function ParamViewModal({
  setInternalModal,
  device,
  sensor,
  fetching,
  nodeData,
}: ParamViewModalProps) {
  var [graphType, setGraphType] = useState<"line" | "bar" | "scatter">("line");

  var [graphProps, setGraphProps] = useState({
    ChartData: {
      i: "",
      title: {
        en: "",
      },
      chartData: [
        {
          phenomena: sensor?.config,
          deviceID: device?.eui,
          elemID: sensor?.elemID,
          label: sensor?.description,
          colour: "#ff0000",
          unit: "",
          lineID: "77b5b7f7-905d-4f6a-9f7a-fb6fbb4f9a73",
          style: "solid",
          connectedNulls: true,
        },
      ],
      type: "",
      chartScale: undefined,
    },
    FetchSuccess: true,
    FetchError: false,
    Type: graphType,
    HideLock: true,
    HideSettings: true,
    propNodeData: nodeData,
  });

  useEffect(() => {
    if (sensor) {
      setGraphProps({
        ChartData: {
          ...graphProps.ChartData,
          chartData: [
            {
              ...graphProps.ChartData.chartData[0],
              phenomena: sensor.config,
              elemID: sensor.elemID,
              label: sensor.description,
            },
          ],
        },
        FetchSuccess: true,
        FetchError: false,
        Type: graphType,
        HideLock: true,
        HideSettings: true,
        propNodeData: nodeData,
      });
    }
  }, [sensor, graphType, nodeData]);

  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {sensor?.description ?? ""}
                </h3>
                {fetching ? (
                  <div className="flex flex-col justify-center items-center">
                    <Spinner colour="fill-blue-600" />
                  </div>
                ) : nodeData?.data[device?.eui ?? ""] === undefined ? (
                  <div className="flex flex-col justify-center items-center">
                    <div className="text-gray-900 text-xl font-semibold mt-2">
                      No Data in the last 7 Days.
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-row justify-around items-center mt-2">
                      <button
                        className={`${
                          graphType === "line"
                            ? "bg-blue-500"
                            : "bg-gray-500 hover:bg-gray-700"
                        } text-white font-bold p-1 rounded`}
                        onClick={() => setGraphType("line")}
                      >
                        <AiOutlineLineChart size={32} />
                      </button>
                      <button
                        className={`${
                          graphType === "bar"
                            ? "bg-blue-500"
                            : "bg-gray-500 hover:bg-gray-700"
                        } text-white font-bold p-1 rounded`}
                        onClick={() => setGraphType("bar")}
                      >
                        <AiOutlineBarChart size={32} />
                      </button>
                      <button
                        className={`${
                          graphType === "scatter"
                            ? "bg-blue-500"
                            : "bg-gray-500 hover:bg-gray-700"
                        } text-white font-bold p-1 rounded`}
                        onClick={() => setGraphType("scatter")}
                      >
                        <AiOutlineDotChart size={32} />
                      </button>
                    </div>
                    <div className="min-w-[300px] w-full h-[400px]">
                      <Graph {...graphProps} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <div className="sm:w-auto">
                  <Button
                    onClick={() => setInternalModal(false)}
                    label={"Close"}
                    colour={"blue"}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
