import { I18nSettings } from "fecha";

type format = (date: Date, format?: string, i18n?: I18nSettings) => string;

export function DataTimeToLocale(date: Date): string {
  const format: format = require("fecha").format;
  return format(date, "YYYY-MM-DD HH:mm:ss");
}

export const convertSecondsToDays = (seconds: number) => {
  seconds = Math.abs(Math.floor(seconds));
  const secondsPerMinute = 60;
  const secondsPerHour = 3600;
  const secondsPerDay = 86400;

  const days = Math.floor(seconds / secondsPerDay);
  seconds %= secondsPerDay;

  const hours = Math.floor(seconds / secondsPerHour);
  seconds %= secondsPerHour;

  const minutes = Math.floor(seconds / secondsPerMinute);
  seconds %= secondsPerMinute;

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};
