import { useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import { ChartType, LineChartData } from "../index";
import _, { get, head } from "lodash";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import { UplinkData } from "../../../../services/dataAPI";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../../../dictionary/ChartsText";
import { selectLanguage } from "../../../../features/user/userSlice";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { FaSortDown, FaSortUp } from "react-icons/fa";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

type keyNumberDatePair = { [key: string]: Number | Date };
type newDataType = { [key: string]: number | Date | keyNumberDatePair };
type dataType = (newDataType | undefined)[];
type SeriesType = dataType[];

function SingleDeviceTable({ ChartData, FetchSuccess, FetchError }: Props) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const nodeData = useAppSelector(selectData);

  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [sortHeader, setSortHeader] = useState<string>("ts");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [maxRows, setMaxRows] = useState<number>(2000);

  const [lines, setLines] = useState<LineChartData[]>(ChartData.chartData ?? []);
  const [groupedSeries, setGroupedSeries] = useState<SeriesType>([]);

  function groupLinesByDeviceID(lines: LineChartData[]) {
    var groupedLines: { [key: string]: LineChartData[] } = {};
    lines.forEach((line) => {
      if (line.deviceID !== undefined) {
        if (groupedLines[line.deviceID] === undefined) {
          groupedLines[line.deviceID] = [];
        }
        groupedLines[line.deviceID].push(line);
      }
    });
    return groupedLines;
  }

  const timeSeriesDataFromGroupedLines = (GroupedLines: {
    [key: string]: LineChartData[];
  }) => {
    var tempTimeSeriesData = _.entries(GroupedLines).map(([deviceID, lines]) => {
      var arr = _.values(nodeData.data[deviceID as keyof DataType]);
      var data: dataType = arr.map((element: UplinkData) => {
        var newData: newDataType = {};
        setDataAvailable(true);
        if (typeof element.ts === "string") {
          newData["ts"] = new Date(element.ts);
          lines.map((line) => {
            var phenomena = line.phenomena;
            var elemID = line.elemID;
            if (phenomena !== undefined && phenomena !== "new" && elemID !== undefined) {
              if (
                element?.data_points?.[phenomena]?.[elemID] !== undefined &&
                element?.data_points?.[phenomena]?.[elemID] !== null
              ) {
                if (!isNaN(Number(element.data_points[phenomena][elemID]))) {
                  newData[phenomena + elemID] = element.data_points[phenomena][elemID];
                }
              }
            }
          });
          return newData;
        }
      });
      return data;
    });
    return tempTimeSeriesData;
  };

  function GetLinesFromParams() {
    setLines(ChartData.chartData ?? []);
  }

  function getMaxRowsFromParams() {
    if (ChartData.chartScale?.y_axis.max !== undefined) {
      setMaxRows(ChartData.chartScale?.y_axis.max);
    }
  }

  function GetDataAndSort() {
    if (nodeData) {
      if (lines.length > 0) {
        var tempseries = lines.map((line) => {
          if (
            line?.phenomena !== undefined &&
            line?.elemID !== undefined &&
            line?.deviceID !== undefined
          ) {
            return timeSeriesDataFromGroupedLines(groupLinesByDeviceID(lines));
          }
        });
        if (tempseries[0]) {
          if (tempseries[0].length > 0) {
            setGroupedSeries(sortSeriesByHeader(tempseries[0], "ts", sortDirection));
          }
        }
      }
    }
  }

  useEffect(() => {
    GetLinesFromParams();
  }, [ChartData.chartData]);

  useEffect(() => {
    getMaxRowsFromParams();
  }, [ChartData.chartScale?.y_axis.max]);

  useEffect(() => {
    GetDataAndSort();
  }, [nodeData, lines, FetchSuccess, FetchError]);

  function GetTableRow(dataLine: newDataType, index: number) {
    var DateColumn = dataLine["ts"] !== undefined && dataLine["ts"] instanceof Date && (
      <td className="w-fit font-semibold text-md text-left block mr-0 pr-0 pl-2">
        {dataLine["ts"].toLocaleString("en-GB")}
      </td>
    );
    var DataColumns = lines.map((line, colIndex) => {
      if (line?.phenomena !== undefined && line?.elemID !== undefined) {
        if (dataLine[line.phenomena + line.elemID] !== undefined) {
          var tempDataLine = dataLine[line.phenomena + line.elemID];
          if (!isNaN(Number(tempDataLine))) {
            return (
              <td key={colIndex} className="text-center mr-auto">
                {line.elemID === "sw_curr0"
                  ? tempDataLine === 0
                    ? "Close"
                    : "Open"
                  : Math.round(Number(tempDataLine) * 10) / 10}
              </td>
            );
          } else {
            return <td className="text-center mr-auto">{"-"}</td>;
          }
        } else {
          return <td className="text-center mr-auto">{"-"}</td>;
        }
      } else {
        return <td className="text-center mr-auto">{"-"}</td>;
      }
    });

    return (
      <tr
        key={index}
        className={`w-full border border-x-0 bg-white hover:bg-gray-300 ${
          index % 2 === 0 && "bg-gray-100"
        }`}
      >
        {DateColumn}
        {DataColumns}
      </tr>
    );
  }

  function MakeSelectedSort({ header }: { header: string }) {
    setSortDirection(flipSortDirection(sortDirection));
    setSortHeader(header);
    setGroupedSeries(
      sortSeriesByHeader(groupedSeries, header, flipSortDirection(sortDirection))
    );
  }

  return (
    <>
      {groupedSeries.length > 0 &&
        (lines[0].deviceID === "new" ? (
          <div className="w-full h-full flex items-center justify-center">
            Select a Device to Show Data
          </div>
        ) : lines[0]?.phenomena === "new" ? (
          <div className="w-full h-full flex items-center justify-center">
            Select a Parameter to Show Data
          </div>
        ) : !dataAvailable ? (
          <div className="w-full h-full flex items-center justify-center">
            No Data Within Range
          </div>
        ) : (
          <div className="w-full h-full flex flex-wrap overflow-clip">
            <div className="w-full">
              {ChartData.title.en !== undefined && (
                <>
                  <div className="font-semibold text-xl w-full">{ChartData.title.en}</div>
                  <hr />
                </>
              )}
            </div>
            <div className="w-full max-h-full block overflow-y-scroll scrollbar-none border-collapse">
              <table className="table table-fixed w-full max-h-full border-collapse">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    <th scope="col">
                      <div
                        className="flex items-center justify-center"
                        onClick={() => {
                          MakeSelectedSort({ header: "ts" });
                        }}
                      >
                        {"Date"}
                        {sortHeader === "ts" &&
                          (sortDirection === "asc" ? (
                            <FaSortDown size={16} />
                          ) : (
                            <FaSortUp size={16} />
                          ))}
                      </div>
                    </th>
                    {lines.map((line, index) => {
                      return (
                        <>
                          {line !== undefined &&
                            line.phenomena !== undefined &&
                            line.elemID !== undefined && (
                              <th scope="col" key={index}>
                                <div
                                  className="flex items-center justify-center"
                                  onClick={() => {
                                    MakeSelectedSort({
                                      header: line.phenomena! + line.elemID!,
                                    });
                                  }}
                                >
                                  {line.label}
                                  {sortHeader === line.phenomena! + line.elemID! &&
                                    (sortDirection === "asc" ? (
                                      <FaSortDown size={16} />
                                    ) : (
                                      <FaSortUp size={16} />
                                    ))}
                                </div>
                              </th>
                            )}
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {groupedSeries.map((series) => {
                    return series.slice(0, maxRows).map((dataLine, index) => {
                      return (
                        <>{dataLine !== undefined && GetTableRow(dataLine, index)}</>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </>
  );

  function sortSeriesByHeader(
    groupedSeries: SeriesType,
    header: string,
    sortDirection: string
  ) {
    var tempGroupedSeries = groupedSeries;
    var onlyDeviceSeries = groupedSeries[0];
    if (onlyDeviceSeries.length > 0) {
      var sortedSeries = _.sortBy(onlyDeviceSeries, [header]);
      if (sortDirection === "desc") {
        tempGroupedSeries[0] = _.reverse(sortedSeries);
        return tempGroupedSeries;
      } else {
        tempGroupedSeries[0] = sortedSeries;
        return tempGroupedSeries;
      }
    } else {
      return tempGroupedSeries;
    }
  }

  function flipSortDirection(direction: string) {
    if (direction === "desc") {
      return "asc";
    } else {
      return "desc";
    }
  }
}

export default SingleDeviceTable;
