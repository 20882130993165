import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserNotification } from "../../features/user/userSlice";
import { handleError } from "../../utils/ErrorHandling";
import { useToggleUserNotificationReadMutation } from "../../services/userAPI";
interface NotificationListProp {
  notification: UserNotification;
}

const DropdownNotification = ({ notifications }: DropdownNotificationProps) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(true);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  let notificationsArray: UserNotification[] = [];

  const [toggleRead, { isError, error: toggleError, data, isSuccess: isToggleSuccess }] =
    useToggleUserNotificationReadMutation();

  if (notifications) {
    for (const [key, value] of Object.entries(notifications)) {
      var newTimestamp = new Date(value.timestamp).toDateString();
      var firstIndex = newTimestamp.indexOf(" ");
      var lastIndex = newTimestamp.lastIndexOf(" ");
      var formattedDate =
        newTimestamp.substring(firstIndex, lastIndex) +
        ", " +
        newTimestamp.substring(lastIndex);

      var formattedValue = {
        ...value,
        date: formattedDate,
      };
      notificationsArray.push(formattedValue);
    }

    notificationsArray.sort(
      (a: any, b: any) => Date.parse(b.timestamp) - Date.parse(a.timestamp)
    );
  }

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const NotificationList = ({ notification }: NotificationListProp): JSX.Element => {
    const handleMarkAsRead = async (
      event: React.MouseEvent<HTMLOrSVGElement>,
      notificationID: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      notificationID && (await toggleRead({ notificationID: notificationID }));
    };

    return (
      <li className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4 cursor-default">
        <div className="relative flex flex-row gap-3 items-center">
          <p className="text-sm">
            {notification.content.length > 255
              ? `${notification?.content.slice(0, 255)}...`
              : notification?.content}
          </p>
          <div className="group">
            {notification.read ? (
              <svg
                className="absolute right-0 top-1 hover:cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 24 24"
                fill="grey"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                onClick={(event) => handleMarkAsRead(event, notification.id)}
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            ) : (
              <svg
                className="absolute right-0 top-1 hover:cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 24 24"
                fill="blue"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                onClick={(event) => handleMarkAsRead(event, notification.id)}
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            )}
            <span className="group-hover:opacity-100 transition-opacity bg-blue-500 px-1 text-xs text-white rounded-md absolute right-3 -top-5 opacity-0 m-4 p-1 mx-auto cursor-default">
              {notification.read ? "Mark as unread" : "Mark as read"}
            </span>
          </div>
        </div>
        <p className="text-xs">{notification.date}</p>
      </li>
    );
  };

  return (
    <>
      <Link
        ref={trigger}
        onClick={() => {
          setNotifying(false);
          setDropdownOpen(!dropdownOpen);
        }}
        to="#"
        className="relative hover:text-primary"
      >
        <span
          className={`absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1 ${
            notifying === false ? "hidden" : "inline"
          }`}
        >
          <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
        </span>

        <svg
          className=""
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
        </svg>
      </Link>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-2.5 mr-3 flex h-90 w-75 min-w-settings flex-col rounded-md border border-stroke bg-white shadow-lg dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${
          dropdownOpen === true ? "block" : "hidden"
        }`}
      >
        <div className="px-4.5 py-3">
          <h4 className="text-sm font-medium text-bodydark2 px-5">Notifications</h4>
          <h2 className="text-xs font-small px-5 text-gray-500">LATEST</h2>
        </div>

        <ul className="flex flex-col h-[70vh] overflow-visible overflow-y-scroll px-5">
          {notificationsArray.length === 0
            ? "no notifications"
            : notificationsArray.map((notification, i) => (
                <NotificationList key={i} notification={notification} />
              ))}
          <li>
            <div className="text-xs py-20 px-10 text-gray-500 text-center border-t border-stroke">
              <p>That's all your unread notifications from the last 30 days.</p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

type DropdownNotificationProps = {
  notifications: Object | null;
};

export default DropdownNotification;
