import { useState, forwardRef, useImperativeHandle, ChangeEvent } from "react";
import { Status } from "../../features/notifications/notificationSlice";
import useGetLanguage from "../../utils/useGetLanguage";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import ToggleSwitch from "../../utils/ToggleSwitch";
export interface NotificationStatusProps {
  status?: Status;
}
export interface NotificationStatusRef {
  getNotificationStatus: () => NotificationStatusProps;
  validate: () => boolean;
}
const NotificationStatus = forwardRef(
  (
    {
      status = {
        active: true,
        current: false,
        triggered_at: new Date(),
        last_trigger: new Date(),
        evaluated_at: new Date(),
      },
    }: NotificationStatusProps,
    ref
  ): JSX.Element => {
    const [error, setError] = useState("");
    const [notificationStatus, setNotificationStatus] = useState<Status>(status);

    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });

    useImperativeHandle(ref, () => ({
      getNotificationStatus: () => ({
        status: notificationStatus,
      }),
      validate: () => {
        // Implement validations here
        return true;
      },
    }));
    const handleActiveCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setNotificationStatus((prev) => ({
        ...prev,
        active: checked,
      }));
    };

    const handleActiveSwitchChange = (isActive: boolean) => {
      setNotificationStatus((prev) => ({
        ...prev,
        active: !isActive,
      }));
    };

    return (
      <div className="mb-6 bg-gray-100 p-5 rounded-lg mt-2">
        {/* Stack */}
        {/* <>
          <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
            {language?.statusComponent?.heading || "Status"}
          </h1>
          <div className="w-full flex items-center p-0">
            <input
              type="checkbox"
              name="active"
              checked={notificationStatus.active}
              onChange={handleActiveCheckChange}
              className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
            />
            <label className="text-gray-700">
              {language?.statusComponent?.active || "Active"}
            </label>
          </div>
          
        </> */}

        {/* Inline */}
        <>
          <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
            {language?.statusComponent?.heading || "Status"}
          </h1>
          <div className="w-full flex items-center p-0">
            {/* <div className="flex items-center mr-4">
              <input
                type="checkbox"
                name="active"
                checked={notificationStatus.active}
                onChange={handleActiveCheckChange}
                className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
              />
              <label className="text-gray-700">
                {language?.statusComponent?.active || "Active"}
              </label>
            </div> */}
            <div className="flex items-center">
              <ToggleSwitch
                isActive={notificationStatus.active}
                onToggle={() => handleActiveSwitchChange(notificationStatus.active)}
              />
              <label className="text-gray-700 ml-2 mr-2">
                {language?.statusComponent?.active || "Active"}
              </label>
            </div>
            {/* <div className="flex items-center">
              <input
                type="checkbox"
                name="current"
                checked={false}
                className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
              />
              <label className="text-gray-700">Current</label>
            </div> */}
          </div>
        </>
      </div>
    );
  }
);

export default NotificationStatus;
