import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { AuthState, login, setVerifiedEmail } from "../features/auth/authSlice";
import { UserPermissions } from "../features/groups/groupSlice";
import {
  setTermsAndConditionsUpdated,
  setUser,
  updateUserSettings,
  User,
  UserSettings,
  toggleNotificationRead,
} from "../features/user/userSlice";

export type UserGroup = {
  group_id: string;
  permissions: UserPermissions;
  group_name: string;
};

export type Notification = {
  id: string;
  timestamp: string;
  content: string;
  read: boolean;
};
type ToggleNotificationReadArgs = { notificationID: string };

/*
API requests for user related data
*/
export const userAPI = createApi({
  reducerPath: "userAPI",
  //base query for all requests: token used in all requests added to header
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    //get user data - Takes token and returns user data
    getUserInfo: builder.query({
      query: () => {
        return {
          url: "/user",
        };
      },

      //sets the type of the success response
      transformResponse: (result: User) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          //on success, dispatch the user data to the user slice and the verified email to the auth slice
          const { data } = await queryFulfilled;
          console.log(" getUserInfo: builder.query");
          dispatch(
            setUser({
              username: data.username,
              email: data.email,
              phone_number: data.phone_number,
              id: data.id,
              groups: data.groups,
              email_verified: data.email_verified,
              terms_and_conditions_version: data.terms_and_conditions_version,
              settings: data.settings,
              latest_terms_accepted: data.latest_terms_accepted,
              notifications: data.notifications,
            })
          );
          setTermsAndConditionsUpdated(data.latest_terms_accepted ?? false);
          if (data.email_verified !== undefined && data.email_verified !== null) {
            dispatch(setVerifiedEmail(data.email_verified));
          }
        } catch (error) {
          //console.log(error);
        }
      },
      providesTags: ["User"],
    }),
    getGroupUserInfo: builder.query({
      query: ({ groupID, userID }: { groupID: string; userID: string }) => {
        return `/user/${groupID}/${userID}`;
      },
    }),
    toggleUserNotificationRead: builder.mutation({
      query: ({ notificationID }: ToggleNotificationReadArgs) => {
        return {
          url: `/user/${notificationID}/readtoggle`,
          method: "PATCH",
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(
        args: ToggleNotificationReadArgs,
        { dispatch, queryFulfilled }
      ) {
        const { notificationID } = args;
        try {
          const {
            data: { message },
            meta,
          } = await queryFulfilled;
          meta?.response?.status !== 200
            ? toast.error(message)
            : dispatch(toggleNotificationRead(notificationID));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateUser: builder.mutation({
      query: ({
        username,
        email,
        phone_number,
        id,
        password,
      }: {
        username: string;
        email: string;
        phone_number: string;
        id: string;
        password: string;
      }) => {
        return {
          url: "/user/" + id + "/update",
          method: "PUT",
          body: { username, email, phone_number, password },
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {}
      },
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (args: { id: string; password: string }) => {
        return {
          url: "/user/" + args.id + "/delete",
          method: "DELETE",
          body: { password: args.password },
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {}
      },
      invalidatesTags: ["User"],
    }),
    updateUserSettings: builder.mutation({
      query: (settings: UserSettings) => {
        return {
          url: "/user/updateSettings",
          method: "PUT",
          body: { ...settings },
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateUserSettings(args));
          localStorage.setItem("language", args.language);
          //toast.success(data.message);
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetGroupUserInfoQuery,
  useToggleUserNotificationReadMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserSettingsMutation,
} = userAPI;
