import React, { useState, ChangeEvent } from "react";
import { Device } from "../../features/devices/deviceSlice";
import ToggleSwitch from "../../utils/ToggleSwitch";
import { useGetGroupQuery } from "../../services/groupAPI";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";
import { useUpdateDeviceSettingsMutation } from "../../services/deviceAPI";

interface EditDeviceSettingsProps {
  device: Device | null;
}

const EditDeviceSettings = ({ device }: EditDeviceSettingsProps): JSX.Element => {
  const [
    updateSettings,
    {
      data: updateSettingResponse,
      isSuccess: isUpdateSettingsSuccess,
      isLoading: isUpdateSettingsLoading,
      isError: isUpdateSettingsError,
      error: UpdateSettingsError,
    },
  ] = useUpdateDeviceSettingsMutation();
  const groupID = device?.group_id;
  const { data: group, isLoading, error } = useGetGroupQuery({ id: String(groupID) });

  const recipients = mapObjectToArray(group?.users || {}).filter(
    (user) =>
      user?.permissions?.can_view_devices?.can_view_all ||
      user?.permissions?.can_view_devices?.can_view_any
  );
  const [recipientID, setRecipientID] = useState<string>(
    device?.settings?.offline_notification_recipient_id || ""
  );

  const handleRecipientChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value: newRecipientID } = event.target;
    const newRecipient = recipients?.find(
      (recipient) => recipient.user_id === newRecipientID
    );
    newRecipient && setRecipientID(newRecipient.user_id);
  };

  const handleToggleNotification = async () => {
    const updatedDevice = {
      ...device,
      settings: {
        ...device?.settings,
        offline_notification_active: !device?.settings?.offline_notification_active,
      },
    };
    device?.id &&
      updatedDevice &&
      (await updateSettings({
        deviceID: device?.id || "",
        deviceSettings: updatedDevice.settings,
        language: null,
      }));
  };
  const handleSaveRecipient = async () => {
    const updatedDevice: any = {
      ...device,
      settings: {
        ...device?.settings,
        offline_notification_recipient_id: recipientID,
      },
    };
    device?.id &&
      updatedDevice &&
      (await updateSettings({
        deviceID: device?.id || "",
        deviceSettings: updatedDevice.settings,
        language: null,
      }));
  };

  return (
    <>
      <div className="w-full max-w-2xl p-6 bg-white border rounded-lg shadow-md ml-0 mb-5 mt-5">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">
              Device offline notification
            </h2>
            <p className="text-base text-gray-500">
              Email notification when a device has been offline for 24 hours.
            </p>
          </div>
          <ToggleSwitch
            isActive={device?.settings?.offline_notification_active ?? true}
            onToggle={handleToggleNotification}
          />
        </div>
        {device?.settings?.offline_notification_active && (
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
            <label className="text-base font-medium text-gray-700 sm:mr-4 mb-2 sm:mb-0">
              Send email to
            </label>
            <select
              className="flex-grow border border-gray-300 rounded p-3 text-base mb-2 sm:mb-0"
              name="selectInput"
              value={recipientID}
              onChange={handleRecipientChange}
            >
              <option value="">Select a Recipient</option>
              {recipients && recipients.length > 0 ? (
                recipients.map((recipient) => (
                  <option key={recipient.user_id} value={recipient.user_id}>
                    {`${recipient.username}`}
                  </option>
                ))
              ) : (
                <option value="">No recipient Found</option>
              )}
            </select>
            <button
              disabled={
                recipientID === device?.settings?.offline_notification_recipient_id ||
                recipientID === ""
              }
              className={
                "bg-blue-500 hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-50 text-white text-base font-bold py-2 px-4 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105 ml-0 sm:ml-4"
              }
              onClick={handleSaveRecipient}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDeviceSettings;
